import colors from 'constants/colors';
import styled from 'styled-components';

export const FaultCountItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MetricWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const MetricLabel = styled.div`
  color: ${colors.gray500};
  text-transform: lowercase;
  font-size: 12px;
  font-weight: 400;
`;

export const MetricCounter = styled.div`
  font-style: normal;
`;

export const FaultsCountWrapper = styled.div<{ $size: string }>`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 4px;
  ${(props) =>
    props.$size === 'small' &&
    `
    ${MetricWrapper} {
      font-size: 14px;
      line-height: 18px;
      ${MetricLabel} {
        line-height: 14px;
      }
      ${MetricCounter} {
        font-weight: 400;
      }
    }
  `}
  ${(props) =>
    props.$size === 'big' &&
    `
    ${MetricWrapper} {
      font-size: 20px;
      line-height: 22px;
      ${MetricLabel} {
        line-height: 30px;
      }
      ${MetricCounter} {
        font-weight: 500;
      }
    }
  `}
`;
