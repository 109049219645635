import colors from 'constants/colors';
import { CircleIcon } from 'modules/common-ui';
import { useTranslation } from 'react-i18next';
import { FaultCountBySeverity } from '../../../assets/alerts/types';
import {
  FaultCountItem,
  FaultsCountWrapper,
  MetricCounter,
  MetricLabel,
  MetricWrapper,
} from './styles.css';

const DEFAULT_SIZE = 'small';
type CounterSize = 'small' | 'big';

type AssetFaultsCounterProps = Partial<
  FaultCountBySeverity & { size: CounterSize; assets: number }
>;

const renderCounter = (
  label: string,
  size: CounterSize,
  count?: number,
  bulletColor?: string,
): JSX.Element | null => {
  if (count === undefined || (size === 'small' && !count)) return null;

  return (
    <FaultCountItem>
      <MetricWrapper>
        {bulletColor && (
          <CircleIcon color={bulletColor} fill={bulletColor} size={8} />
        )}
        <MetricCounter>{count}</MetricCounter>
      </MetricWrapper>
      <MetricLabel>{label}</MetricLabel>
    </FaultCountItem>
  );
};

export const AssetFaultsCounter: React.FC<AssetFaultsCounterProps> = (
  props: AssetFaultsCounterProps,
) => {
  const [t] = useTranslation('alerts');
  const {
    total,
    critical,
    high,
    medium,
    low,
    unknown,
    assets,
    size = DEFAULT_SIZE,
  } = props;

  return (
    <FaultsCountWrapper $size={size}>
      {renderCounter(
        t('alerts.configuration.common.fields.assets.title'),
        size,
        assets,
      )}
      {renderCounter(
        t('alerts.configuration.fault.fields.severity.counter'),
        size,
        total,
      )}
      {renderCounter(
        t('alerts.configuration.fault.fields.severity.options.critical'),
        size,
        critical,
        colors.red200,
      )}
      {renderCounter(
        t('alerts.configuration.fault.fields.severity.options.high'),
        size,
        high,
        colors.red100,
      )}
      {renderCounter(
        t('alerts.configuration.fault.fields.severity.options.medium'),
        size,
        medium,
        colors.orange100,
      )}
      {renderCounter(
        t('alerts.configuration.fault.fields.severity.options.low'),
        size,
        low,
        colors.purple100,
      )}
      {renderCounter(
        t('alerts.configuration.fault.fields.severity.options.unknown'),
        size,
        unknown,
        colors.neutral200,
      )}
    </FaultsCountWrapper>
  );
};
