import styled from 'styled-components';

import colors from 'constants/colors';
import { resizeAnimation } from 'modules/common-ui';

export const Button = styled.div`
  border-radius: 5px;
  background: #ffffff;
  height: 100%;
  display: flex;
  color: #0a0a0c;
  user-select: none;

  cursor: pointer;
  align-items: center;
  transition: background 120ms ease-in 0s;
  box-shadow: #d5d8db 0px 0px 0px 0.5px inset;
  margin-right: 8px;

  :hover {
    background: #eff2f5;
  }

  :active {
    background: #d5d8db;
  }
`;

export const ButtonLeft = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 8px 7px 8px;
  font-size: 14px;
  line-height: 18px;
  border-radius: 5px 0 0 5px;
  white-space: nowrap;
  width: 100%;
`;

export const TagList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-bottom: -4px;
  display: flex;
  flex-wrap: wrap;
`;

export const Tag = styled.li`
  font-size: 12px;
  line-height: 14px;
  color: ${colors.gray850};
  padding: 2px 6px;
  background: ${colors.gray100};
  border-radius: 3px;
  align-items: center;
  margin-right: 2px;
  margin-bottom: 4px;
  justify-content: center;
  white-space: wrap;
`;

export const ButtonRight = styled.div`
  border-radius: 0 5px 5px 0;
  display: flex;
  align-items: center;
  padding: 7px 8px 7px 8px;
  width: 32px;
  user-select: none;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  & > div:first-child {
    width: 100%;
  }
  width: fit-content;
`;

export const Menu = styled.div<{
  noPadding?: boolean;
  alignRight?: boolean;
  marginAlignRight: number;
}>`
  margin-top: 2px;
  position: absolute;
  background: ${colors.white};
  ${(props) => !props.noPadding && 'padding: 8px;'}
  min-width: 225px;
  border: 1px solid ${colors.gray300};
  border-radius: 5px;
  padding: 0 0 8px 0;
  box-shadow: 0 9px 24px rgba(15, 15, 15, 0.2), 0 3px 6px rgba(15, 15, 15, 0.1),
    0 0 0 rgba(15, 15, 15, 0.05);
  animation-name: ${() => resizeAnimation};
  animation-duration: 200ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in;
  ${(props) => props.alignRight && `right: ${props.marginAlignRight}px;`}
  z-index: 2;
`;

export const ActionBar = styled.div`
  margin-top: 4px;
  margin-left: -3px;

  span + span {
    margin-left: 8px;
  }
`;

export const Separator = styled.span`
  user-select: none;
`;

export const ActionWrapper = styled.div`
  padding: 8px;
`;

export const Action = styled.span`
  color: ${colors.gray500};
  font-size: 12px;
  line-height: 14px;
  padding: 3px;

  cursor: pointer;
  transition: background 120ms ease-in 0s;

  :hover {
    background-color: rgba(56, 58, 60, 0.08);
    border-radius: 3px;
  }

  :active {
    background-color: rgba(146, 148, 151, 0.2);
  }

  user-select: none;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 320px;
  overflow-y: auto;
`;

export const ListHelperText = styled.div`
  font-size: 12px;
  line-height: 14px;
  height: 14px;
  width: 100%;
  color: ${colors.gray500};
  padding: 0 8px;
  margin-bottom: 8px;
  user-select: none;
`;

export const NoResultsHelperText = styled.div`
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  color: ${colors.gray850};
  padding: 0 8px;
  user-select: none;
  min-height: 28px;
`;

export const Row = styled.div<{ noRadius?: boolean }>`
  display: flex;
  align-items: center;

  ${(props) => !props.noRadius && 'border-radius: 5px;'}
  transition: background 120ms ease-in 0s;

  :hover {
    background: rgba(45, 47, 48, 0.08);
  }

  :active {
    background: rgba(45, 47, 48, 0.16);
  }
`;

export const ListRow = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
  align-items: flex-start;

  transition: background 120ms ease-in 0s;
  padding: 2px 8px;
`;

export const Label = styled.span`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.gray850};
  padding: 6px 0px;
`;
